import request from "@/utils/request";

const NewsApi= {
    getNewsList(params) {
        return request({
            method: 'GET',
            url: '/leagueNews/list/web',
            params: params
        })
    },
    getNewsDetails(id){
        return request({
            method: 'GET',
            url: '/leagueNews/detail/web/' + id,
        })
    },
    getNewsImages(params) {
        return request({
            method: 'GET',
            url: '/leagueNews/list/web/image',
            params: params
        })
    },
    getCategoryList(categoryType) {
        return request({
            method: 'GET',
            url: '/newsCategory/getCategoryListWeb/' + categoryType
        })
    }
}

export default NewsApi;