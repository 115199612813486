import axios from "axios";

const BASEURL = '/league/api'

const request = axios.create({
    baseURL: BASEURL
})

request.interceptors.response.use(
    response => {
        const res = response.data
        if (response.status !== 200) {
            console.log('ERROR',res.message)
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('ERROR' + error)
        return Promise.reject(error)
    },
)
export default request;