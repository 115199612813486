<template>
  <div>
    <!-- 导航栏 -->
    <div
      class="nav-container"
      :class="{ 'hidden': hidden }"
      :style="{left: scrollX}"
    >
      <div class="nav">
        <el-image
          class="logo"
          :src="require('@/assets/img/common/logo.png')"
        />
        <div class="menu">
          <el-menu
            class="menu"
            :default-active="activeIndex"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item
              index="home"
              class="menu-item"
            >
              首页
            </el-menu-item>
            <el-menu-item
              index="league"
              class="menu-item"
            >
              联赛介绍
            </el-menu-item>
            <el-menu-item
              index="teams"
              class="menu-item"
            >
              参赛队伍
            </el-menu-item>
            <el-menu-item
              index="data"
              class="menu-item"
            >
              数据中心
            </el-menu-item>
            <el-menu-item
              index="news"
              class="menu-item"
            >
              新闻公告
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <!-- 解决导航栏脱离文档流问题，占位 -->
    <div class="container-false" />
  </div>
</template>

<script>

export default {
  name: "CustomNav",
  data() {
    return {
      activeIndex: 'home',
      prevScrollTop: 0, // 用于存储前一个滚动位置
      hidden: false,
      scrollX: 0,
      scrollThreshold: 200
    }
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path?.split('/')[1];
    }
  },
  mounted() {
    // 更新代码时，watch监听不到
    this.activeIndex = this.$route.path?.split('/')[1];
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    handleSelect(key) {
      if(this.$route.path != `/${key}`) {
        window.scrollTo(0, 0);
        this.$router.push(`/${key}`)
      }
    },

    /**
     * 1、解决吸顶导航栏不跟随滚动条水平滚动的问题
     * 2、上下滑动滚动条动态控制导航栏（消失/出现）
     */
    handleScroll() {
      // 1、获取当前滚动位置
      const currentScrollTop = window.scrollY;
      const scrollDistance = Math.abs(currentScrollTop - this.prevScrollTop);
      if(scrollDistance >= this.scrollThreshold) {
        // if (currentScrollTop > this.prevScrollTop) {
        //   // 向下滚动
        //   this.hidden = true;
        // } else if (currentScrollTop < this.prevScrollTop || currentScrollTop == 0) {
        //   // 向上滚动
        //   this.hidden = false;
        // }
        this.hidden = currentScrollTop > this.prevScrollTop ? true : false;
        // 更新前一个滚动位置
        this.prevScrollTop = currentScrollTop;
      }
      // 解决吸顶导航栏不跟随滚动条滚动问题
      this.scrollX = (-window.scrollX || window.pageXOffset) + 'px';
    },
  },
}
</script>

<style scoped>

.nav-container {
  width: 100%;
  min-width: 1305px;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  transition: top 0.6s;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.container-false{
  height: 80px;
  width: 100%;
}

.hidden{
  top: -80px;
}

.nav{
  height: 80px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.logo{
  width: auto;
  width: 360px;
  height: 40px;
  display: block;
}

.menu{
  width: 600px;
  height: 100%;
  margin-right: 60px;
}

.el-menu{
  width: 100% !important;
  height: 100%;
  border: none !important;

}

li.el-menu-item {
  padding: 0 !important;
  width: 20%;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC-Medium;
  font-size: 17px;
  border: none !important;
}

li.is-active {
  border: none !important;
  position: relative;
  font-size: 18px;
  font-family: SourceHanSans-Medium;
}

li.el-menu-item:hover {
  background: rgba(0, 152, 139, 0.1) !important;
}

::v-deep li.is-active::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 40%;
  width: 20%;
  height: 4px;
  background-color: #00988B;
  border-radius: 78px;
}
</style>