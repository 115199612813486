import {getToken} from '@/utils/token-util';

const REDIRECT_PATH = '/redirect';
let PROJECT_NAME = '';
/**
 * 修改浏览器标题
 * @param title 标题
 */
export function setDocumentTitle(title) {
  const names = [];
  PROJECT_NAME = JSON.parse(getToken()).gameName;
  if (PROJECT_NAME) {
    names.push(PROJECT_NAME);
  }
  if (title) {
    names.push(title);
  }
  document.title = names.join(' - ');
}

/**
 * 路由切换更新浏览器标题
 * @param route 路由信息
 * @param $t i18n
 */
export function updateDocumentTitle(route) {
  const { path, name } = route;
  if (path.includes(REDIRECT_PATH)) {
    return;
  }
  const title = name;
  setDocumentTitle(title);
}
