<template>
  <div class="container">
    <custom-nav />
    <router-view />
    <custom-footer v-if="!loading" />
  </div>
</template>

<script>
import {updateDocumentTitle} from '@/utils/document-title-util';
import CustomNav from "@/components/custom-nav.vue";
import CustomFooter from "@/components/custom-footer.vue";

export default {
  name: 'App',
  components: {CustomFooter, CustomNav},
  data() {
    return {
      loading: true
    }
  },
  watch: {
    $route() {
      this.setDocumentTitle();
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      this.loading = true;
    });
  },
  methods:{
    /* 路由切换更新浏览器标题 */
    setDocumentTitle() {
      updateDocumentTitle(
          this.$route
      );
    }
  },
}
</script>

<style scoped>

.container{
  width: 100%;
  min-width: 1305px;
  background-color: #f6f6f6;
}
</style>

<style>

html, body{
  margin: 0 !important;
  padding: 0 !important;
}
</style>