const NewsConst = {
    category2IndexMap: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        '-1': 6,
    },
    index2categoryMap: {
        0: undefined,
        1: 1,
        2: 2,
        3: 3,
        5: 4,
        6: -1,
    },
    newsMenu:[
        "全部",
        "官方新闻",
        "各队新闻",
        "媒体报道",
        "图片新闻",
        "视频直播",
        "公告通知",
    ]
}

export default NewsConst;