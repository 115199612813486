import IntroduceApi from "@/api/introduce";
import HomeApi from "@/api/home";
import TeamApi from "@/api/team";
import NewsApi from "@/api/news";
import DataApi from "@/api/data";
export default  {
    ...IntroduceApi,
    ...HomeApi,
    ...TeamApi,
    ...NewsApi,
    ...DataApi
}