import VueRouter from "vue-router";
import Vue from 'vue';
import NProgress from 'nprogress';
import store from '@/store';
import {getToken, setToken} from '@/utils/token-util';

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return {y: 0};
    },
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: '首页',
            component: () => import('@/views/home.vue')
        },
        {
            path: '/league',
            name: '联赛介绍',
            component: () => import('@/views/league-info.vue')
        },
        {
            path: '/teams',
            name: '参赛队伍',
            component: () => import('@/views/teams.vue')
        },
        {
            path: '/teams/:teamNo',
            name: '参赛队伍',
            component: () => import('@/views/teams.vue')
        },
        {
            path: '/data',
            name: '数据中心',
            component: () => import('@/views/data-center.vue')
        },
        {
            path: '/news',
            name: '新闻公告',
            component: () => import('@/views/news-notice.vue')
        },
        {
            path: '/news/details/:id',
            name: '新闻详情',
            component: () => import('@/views/news-details.vue')
        },
        {
            path: '/teams/player/:playerNo',
            name: '棋手介绍',
            component: () => import('@/views/player-info.vue')
        }

    ]
});

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
    if (!from.path.includes('/redirect')) {
        NProgress.start();
    }
    if (!getToken()) {
        store.dispatch('game/fetchGameInfo').then((res) => {
            if (res) {
                setToken(JSON.stringify(res), true);
                next({...to, replace: true});
            }
        }).catch((e) => {
            console.error(e);
            next();
        });
    } else {
        next();
    }
});

router.afterEach((to) => {
    if (!to.path.includes('/redirect') && NProgress.isStarted()) {
        setTimeout(() => {
            NProgress.done(true);
        }, 200);
    }
});
export default router;