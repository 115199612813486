import request from "@/utils/request";

const IntroduceApi = {
    getLeagueList(params) {
        return request({
            method: 'GET',
            url: 'game/page/list',
            params: params
        })
    },
    getLeagueInfo(id){
        return request({
            method: "GET",
            url:'/game/detail/' + id
        })
    }
}

export default IntroduceApi;