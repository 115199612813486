import Vue from 'vue'
import App from './App.vue'
import store from './store';
import './plugins/element.js'
// 引入api
import api from "@/api";
// 引入常量
import constant from "@/constants";
// 引入字体
import '@/fonts/font.css'
// 引入vue-router
import VueRouter from "vue-router";
// 引入router
import router from "@/router";
// 引入瀑布流插件
import waterfall from 'vue-waterfall2'
// 引入图片预览插件
import hevueImgPreview from 'hevue-img-preview'

Vue.use(hevueImgPreview)
Vue.use(waterfall)
Vue.use(VueRouter)

Vue.prototype.$api = api;
Vue.prototype.$constant = constant;
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
  router: router
}).$mount('#app')
