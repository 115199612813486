import request from "@/utils/request";

const DataApi = {
    getPlayerRankList(params){
        return request({
            method: "GET",
            url:'/player/rank/list',
            params: params
        })
    }
}

export default DataApi;