/**
 * 登录状态管理
 */
import {getGameInfo} from '@/api/config';

export default {
  namespaced: true,
  state: {
    // 当前赛事信息
    info: null
  },
  mutations: {
    // 设置当前赛事信息
    setGameInfo(state, info) {
      state.info = info;
    }
  },
  actions: {
    /**
     * 请求赛事信息
     */
    async fetchGameInfo({commit}) {
      const result = await getGameInfo().catch(() => {});
      if (!result) {
        return {};
      }
      // 赛事信息
      commit('setGameInfo', result);
      return result;
    },
    /**
     * 更新用户信息
     */
    setGameInfo({commit}, value) {
      commit('setGameInfo', value);
    },
  }
};
