const TeamConst = {
    positionMap:{
        1: '名誉总领队',
        2: '名誉领队',
        3: '领队',
        4: '副领队',
        5: '总教练',
        6: '教练',
    },
    teamNameMap: {
        '重庆野狐围棋队': ['重庆', '野狐围棋队'],
        '成都农商银行队': ['成都','农商银行队'],
        '深圳龙华队': ['深圳', '龙华队'],
        '苏泊尔杭州队': ['苏泊尔','杭州队'],
        '龙元明城杭州队': ['龙元明城', '杭州队'],
        '贵州仁怀酱香队': ['贵州仁怀','酱香队'],
        '上海清一队': ['上海', '清一队'],
        '加加食品天津队': ['加加食品', '天津队'],
        '上海建桥学院队': ['上海', '建桥学院队'],
        '山西元工弘弈队': ['山西', '元工弘弈队'],
        '浙江浙商证券队': ['浙江', '浙商证券队'],
        '民生Visa北京队': ['民生Visa', '北京队'],
        '衢州烂柯队': ['衢州', '烂柯队'],
        '深圳聂道围棋队': ['深圳','聂道围棋队'],
        '江苏神兽朏朏队': ['江苏','神兽朏朏队'],
        '日照山海大象队': ['日照山海', '大象队']
    },
    gradeMap:{
        'Z01': '初段',
        'Z02': '二段',
        'Z03': '三段',
        'Z04': '四段',
        'Z05': '五段',
        'Z06': '六段',
        'Z07': '七段',
        'Z08': '八段',
        'Z09': '九段'
    },
    roundMap:{
        1: '第一轮',
        2: '第二轮',
        3: '第三轮',
        4: '第四轮',
        5: '第五轮',
        6: '第六轮',
        7: '第七轮',
        8: '第八轮',
        9: '第九轮',
        10: '第十轮',
        11: '第十一轮',
        12: '第十二轮',
        13: '第十三轮',
        14: '第十四轮',
        15: '第十五轮',
        16: '第十六轮'
    },
    rankTypeMap:{
        1: '总战绩',
        2: '主将',
        3: '快棋'
    },
    countryMap:{
        1: 'china',
        3: 'korea',
        4: 'japan'
    },
    gameTypeMap:{
        1: '世界大赛',
        2: '国内赛事',
        3: '女子赛事',
        4: '业余赛事',
        5: '国际赛事'
    },
    stationMap:{
        1: '一台',
        2: '二台',
        3: '三台',
        4: '四台'
    }
}

export default TeamConst;