<template>
  <div>
    <div class="footer">
      <div class="footer-content">
        <div class="link">
          <p class="title">
            友情链接
          </p>
          <p>中国围棋协会官网</p>
          <p>中国女子围甲联赛</p>
          <p>雅居乐清水湾官网</p>
        </div>
        <div
          class="map"
          style="color: white"
        >
          <p class="title">
            地图支持
          </p>
          <p>联赛介绍</p>
          <p>联系我们</p>
          <p>技术支持</p>
        </div>
        <div class="line">
          ©2012-2022 XXX All Rights Reserved 京ICP备12318230号-5 京公网安备 440222602002278号
        </div>
        <div class="wechat">
          <div class="wechat-content">
            <div style="font-size: 20px; font-weight: 600; margin: 25px 0 32px; font-family: PingFang SC">
              围甲联赛小程序
            </div>
            <el-image
              style="height: 121px; width: 121px"
              :src="require('@/assets/img/common/wechat.jpg')"
            />
            <div style="font-weight: 500; font-size: 14px; font-family: PingFang SC; margin-top: 23px">
              微信扫一扫
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomFooter"
}
</script>

<style scoped>
.footer{
  width: 100%;
  height: 374px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1E1E1E;
  /*margin-bottom: -374px;*/
}

.footer-content{
  width: 1200px;
  height: 274px;
  display: grid;
  grid-template-areas:
      'link map wechat'
      'line line wechat';
  grid-template-rows: 182px 92px;
  grid-template-columns: 375px 375px 450px;
}

.link{
  grid-area: link;
  color: white;
  font-size:14px;
  margin-top: 25px;
}

.map{
  grid-area: map;
  color: white;
  font-size: 14px;
  margin-top: 25px;
}

.line{
  grid-area: line;
  color: #FFFFFF;
  opacity:0.5;
  font-size: 14px;
  padding-top: 70px;
}

.title{
  font-size: 16px;
  font-weight: bold;
  margin-top: 19px;
}

.wechat{
  grid-area: wechat;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.wechat-content {
  height: 274px;
  width: 195px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>