import request from "@/utils/request";

const HomeApi = {
    getRankList(gameNo){
        return request({
            method: "GET",
            url: '/leagueRank/all/' + gameNo
        })
    },
    getPlayoffRankList(gameNo){
        return request({
            method: "GET",
            url: '/leagueRank/all/playoff/' + gameNo
        })
    },
    getConfig(){
        return request({
            method: 'GET',
            url: '/config/men/game'
        })
    },
    getHomeNews() {
        return request({
            method: 'GET',
            url: '/newsHome/getHome'
        })
    },
    getRounds(params) {
        return request({
            method: 'GET',
            url: '/game/round/list',
            params: params
        })
    },
    getGameByRound(params) {
        return request({
            method: 'GET',
            url: 'game/round/list/detail',
            params: params
        })
    },
    getBanner(params) {
        return request({
            method: 'GET',
            url: '/game/banner/list',
            params: params
        })
    },
    getHomeNotice() {
        return request({
            method: 'GET',
            url: '/leagueNews/notice/getHome'
        })
    },
    getOfficialPartner(params){
        return request({
            method: 'GET',
            url: '/game/partner/list',
            params: params
        })
    },
    getMediaList(params) {
        return request({
            method: 'GET',
            url: '/media/list',
            params: params
        })
    },
    getPlayoffsBattles(params){
        return request(({
            method: 'GET',
            url: 'game/round/playoff/battle',
            params: params
        }))
    }
}

export default HomeApi;