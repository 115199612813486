import request from "@/utils/request";

const TeamApi= {
    getTeamList(params) {
        return request({
            method: 'GET',
            url: '/game/team/list',
            params: params
        })
    },
    getTeamMembers(params) {
        return request({
            method: 'GET',
            url: '/team/player/list',
            params: params
        })
    },
    getRecords(teamNo) {
        return request({
            method: 'GET',
            url: '/team/record/list?teamNo=' + teamNo
        })
    },
    getTeamInfo(teamNo) {
        return request({
            method: 'GET',
            url: '/team/detail/' + teamNo
        })
    },
    getGameList(params){
        return request(({
            method: 'GET',
            url: '/game/round/team/against/list',
            params: params
        }))
    },
    getPartner(params) {
        return request({
            method: 'GET',
            url: '/team/partner/detail',
            params: params
        })
    },
    getPlayerAwards(params){
        return request({
            method: 'GET',
            url: '/player/awards/list',
            params: params
        })
    },
    getPlayerGrades(params){
        return request({
            method: 'GET',
            url: '/player/experience/list',
            params: params
        })
    },
    getMatchRecords(params){
        return request({
            method: 'GET',
            url: '/game/round/player/list',
            params: params
        })
    },
    getLeagueRecords(params){
        return request({
            method: 'GET',
            url: '/player/record/list',
            params: params
        })
    },
    getPlayerInfo(params,playerNo) {
        return request({
            method: 'GET',
            url: '/player/detail/' + playerNo,
            params: params
        })
    }
}

export default TeamApi;