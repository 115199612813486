const HomeConst = {
    price:{
        1: 'first',
        2: 'second',
        3: 'third'
    },
    turnMap:{
        1: '第一回合',
        2: '第二回合',
        3: '第三回合',
        4: '第四回合',
        5: '第五回合',
        6: '第六回合',
    },
    playoffsRounds: {
        1: '第一轮',
        2: '第二轮',
        3: '第三轮',
        4: '总决赛'
    },
    nodeList:[
        {
            start: 'root',
            end: 'node-1',
        },
        {
            start: 'root',
            end: 'node-2',
        },
        {
            start: 'node-1',
            end: 'node-3',
        },
        {
            start: 'node-1',
            end: 'node-4',
        },
        {
            start: 'node-2',
            end: 'node-5',
        },
        {
            start: 'node-2',
            end: 'node-6',
        },
        {
            start: 'node-4',
            end: 'node-7',
        },
        {
            start: 'node-4',
            end: 'node-8',
        },
        {
            start: 'node-5',
            end: 'node-9',
        },
        {
            start: 'node-5',
            end: 'node-10',
        },
        {
            start: 'node-8',
            end: 'node-11',
        },
        {
            start: 'node-8',
            end: 'node-12',
        },
        {
            start: 'node-9',
            end: 'node-13',
        },
        {
            start: 'node-9',
            end: 'node-14',
        }
    ],
    finalNodeList:[
        {
            start: 'root-1',
            end: 'node-1',
        },
        {
            start: 'root-1',
            end: 'node-2',
        },
        {
            start: 'root-3',
            end: 'node-3',
        },
        {
            start: 'root-3',
            end: 'node-4',
        },
        {
            start: 'root-5',
            end: 'node-5',
        },
        {
            start: 'root-5',
            end: 'node-6',
        }
    ],
    demotionNodeList:[
        {
            start: 'node-1',
            end: 'node-3',
        },
        {
            start: 'node-1',
            end: 'node-4',
        },
        {
            start: 'node-2',
            end: 'node-5',
        },
        {
            start: 'node-2',
            end: 'node-6',
        },
        {
            start: 'node-3',
            end: 'node-7',
        },
        {
            start: 'node-3',
            end: 'node-8',
        },
        {
            start: 'node-6',
            end: 'node-9',
        },
        {
            start: 'node-6',
            end: 'node-10',
        },
        {
            start: 'node-7',
            end: 'node-11',
        },
        {
            start: 'node-7',
            end: 'node-12',
        },
        {
            start: 'node-10',
            end: 'node-13',
        },
        {
            start: 'node-10',
            end: 'node-14',
        }
    ],

    FinalDefaultTeamName:{
        1: {
            homeTeam: ['争冠组2', '季军组2', '5-6名2'],
            visitingTeam: ['争冠组1', '季军组1', '5-6名1']
        },
        2: {
            homeTeam: ['争冠组1', '季军组1', '5-6名1'],
            visitingTeam: ['争冠组2', '季军组2', '5-6名2']
        }
    }
}

export default HomeConst;